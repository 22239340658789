export const CONTENTFUL_ENVIRONMENTS = ["develop", "staging", "master", "main"];

export type TContentfulEnvironment = "develop" | "staging" | "master" | "main" | undefined;

export const MockedTopics = [
  { name: "Brochures", value: "Brochures" },
  { name: "Essentials", value: "Essentials" },
  { name: "Product Sheets", value: "Product Sheets" },
  { name: "Industry Sheets", value: "Industry Sheets" },
  { name: "Infographics", value: "Infographics" },
];

export interface IIndustryCategory {
  image: string;
  placeholder: string;
  id: string;
  imgAlt: string;
  heading: string;
  subheading: string;
  ctaLink: string;
}

export const INDUSTRY_CATEGORIES_ORDER: string[] = [
  "5Asv3kaMtfJWXS58Q6Ligh",
  "4cNYvNaawe169A85mKXMFk",
  "2bdSOjUZ6oSkLh4PieXaKf",
  "2LlMk5WHU8XlDK5EHIWtG5",
  "3SUHRL8X2510AdPKNKNh2p",
  "1s71zQAOE1lgqExvLYxUwW",
];

export const NO_PREVIEW_PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAdCAMAAADfG89gAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACZUExURc3e7s7f7s/f787e7tDg78ja7LrQ5rvQ5rDJ4pa32qTA3pe42rvQ58TX6pO12a3H4b/U6M/g77/T6JK12MXY6sHV6aK/3dPi8MDU6Zi42tLi8KC+3cLW6c/f7pq628DU6Jy726C93aG/3Z283LnP5rfO5Z+93M7f78bZ66K/3sfa7NLh8MXY65K02KXB36fC36jD4MLV6cLW6rpQTHAAAAAJcEhZcwAACxIAAAsSAdLdfvwAAACHSURBVDhP7c3NDoIwEEbR6VAQAQXBVkURRaGg8qPv/3AmZPa0W8JZ9ZvkprCYO4Zo0VMPcttZ2Rxp6nDctef5bkBzGttsw2jH4zDZMzpNYUIejv5JpOeLQZJdRX7Du0FSZI9nWRkkSsi0ls3rbfDLp+36Yei7ttZNAL7B6EdTh8KRormYJYA/+XAITIlotJUAAAAASUVORK5CYII=";
