/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from "@contentful/rich-text-types";
import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
import QuoteRequest from "../mediaAndText/QuoteRequest";
import AppButton from "../buttons/AppButton";
import { NO_PREVIEW_PLACEHOLDER } from "@/contentful/constants";
import CtaVersion from "../buttons/CtaVersion";
import { usePathname } from "next/navigation";
// import { renderColumn } from "./RowBlock";

interface RichTextRendererProps {
  richTextDocument: Document;
  id?: string;
  locations?: boolean;
  className?: string;
  empoweringEmployees?: boolean;
  productOfferings?: boolean;
  associatedSolution?: any;
  shareThisArticle?: string;
  textColor?: string;
  labelColor?: string;
  columnBlock?: boolean;
  paddingClass?: string;
  closeModal?: () => void;
}

const RichTextRenderer: React.FC<RichTextRendererProps> = ({
  richTextDocument,
  id,
  locations,
  className,
  empoweringEmployees,
  associatedSolution,
  productOfferings,
  shareThisArticle,
  textColor,
  labelColor,
  columnBlock,
  paddingClass = "py-16",
  closeModal,
}) => {
  const pathname = usePathname();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  const Components = documentToReactComponents(richTextDocument, {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        if (empoweringEmployees) {
          return (
            <AppText className={cn("text-pretty mb-6", className, textColor)} type="SUB_HEADING_LARGE">
              {children}
            </AppText>
          );
        }
        return (
          <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="HEADLINE_MEDIUM">
            {children}
          </AppText>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        if (empoweringEmployees) {
          return (
            <AppText className={cn("text-royal-blue text-pretty mt-6 mb-2", className, textColor)} type="LABEL_LARGE">
              {children}
            </AppText>
          );
        } else if (productOfferings) {
          return (
            <AppText className={cn("text-pretty", className, textColor)} type="HEADLINE_SMALL">
              {children}
            </AppText>
          );
        } else {
          return (
            <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="HEADLINE_SMALL">
              {children}
            </AppText>
          );
        }
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="TITLE_LARGE">
            {children}
          </AppText>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => (
        <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="SUB_HEADING_LARGE">
          {children}
        </AppText>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="SUB_HEADING_MEDIUM">
          {children}
        </AppText>
      ),
      [BLOCKS.HEADING_6]: (node, children) => {
        if (pathname.endsWith("new-request-a-quote") || pathname.endsWith("request-quote")) {
          return (
            <div className="bg-royal-blue-10 w-fit px-2 py-1 mt-6 mb-6">
              <AppText
                className={cn(
                  "text-pretty text-14 font-[500] font-sairaSemiCondensed tracking-[1.5px] leading-[1.7]",
                  className,
                  labelColor
                )}
                type="SUB_HEADING_SMALL"
              >
                {children}
              </AppText>
            </div>
          );
        }
        return (
          <AppText className={cn("text-pretty mt-8 mb-4", className, labelColor)} type="SUB_HEADING_SMALL">
            {children}
          </AppText>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <AppText className={cn("mb-4 text-pretty", className, textColor)} type="BODY_MEDIUM">
          {children}
        </AppText>
      ),
      ["hyperlink"]: (node, children) => (
        <a href={node.data.uri} onClick={closeModal ? closeModal : undefined}>
          <span
            className={cn(
              "font-saira text-16 leading-24 text-pretty inline font-semibold tracking-tighter text-black break-words",
              className,
              textColor
            )}
          >
            {children}
          </span>
        </a>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <AppText className={cn("text-pretty mt-8 mb-4", className, textColor)} type="SUB_HEADING_LARGE">
          {children}
        </AppText>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className={cn("list-decimal mb-[8px] list-inside richTextList", className, textColor)}>{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ol className={cn("list-disc mb-[8px] list-inside richTextList", className)}>{children}</ol>
      ),
      [BLOCKS.HR]: () => <hr className={cn("mt-8 mb-4 border-black", className)} />,
      ["embedded-entry-block"]: (node) => getLastBlock(node, shareThisArticle, columnBlock),
    },
    preserveWhitespace: true,
  });

  return (
    <div
      id={id}
      className={cn(
        !locations &&
          `flex flex-col ${paddingClass} w-full px-4 lg:max-w-[632px] mx-auto xl:max-w-[734px] 2xl:max-w-[872px] overflow-x-auto`
      )}
    >
      <>
        {Components}
        {associatedSolution && <AssociatedSolution {...associatedSolution} shareThisArticle={shareThisArticle} />}
      </>
    </div>
  );
};

export default React.memo(RichTextRenderer);

const getLastBlock = (node: any, shareThisArticle?: string, columnBlock?: boolean) => {
  if (node.data?.target?.itemType === "compenentCta") {
    return <CtaVersion {...node.data?.target?.fields} className="my-10" />;
  }

  const fields = node.data?.target?.fields;

  if (
    node.data?.target?.fields?.image?.[0]?.thumbnails?.transformBaseUrl ||
    node.data?.target?.image?.[0]?.thumbnails?.transformBaseUrl
  ) {
    const img =
      node.data?.target?.fields?.image?.[0]?.thumbnails?.transformBaseUrl ||
      node.data?.target?.image?.[0]?.thumbnails?.transformBaseUrl;

    let aspect = "16/9";

    if (node.data?.target?.aspectRatioX && node.data?.target?.aspectRatioY) {
      aspect = `${node.data?.target?.aspectRatioX}/${node.data?.target?.aspectRatioY}`;
    }

    return (
      <div
        style={{ aspectRatio: aspect }}
        className={cn("relative flex items-center justify-center", columnBlock ? "" : "mt-8")}
      >
        <Image
          src={img ?? "/placeholders/no-preview.png"}
          alt="image"
          sizes="(max-width: 1024px) 100vw, 450px"
          width={0}
          height={0}
          className=" object-contain w-full"
        />
      </div>
    );
  }

  if (!fields) return null;

  const tmpObj: any = {
    id: node.data?.target?.sys?.id,
    label: fields.label || "",
    heading: fields.heading || "",
    content: fields.content || "",
    primaryCtaLabel: fields.primaryCtaLabel || "",
    primaryCtaLink: fields.primaryCtaLink || "",
    secondaryCtaLabel: fields.secondaryCtaLabel || "",
    secondaryCtaLink: fields.secondaryCtaLink || "",
    background: fields.background,
    mediaAlign: fields.mediaAlign || "Left",
    image: fields.media?.[0]?.thumbnails?.transformBaseUrl,
  };

  return (
    <div className="mt-12">
      <QuoteRequest richText {...tmpObj} />
      <div className="flex mt-10">
        <AppText type="BUTTON_SMALL">{shareThisArticle || "Share this article"}</AppText>
        <div className="flex gap-3 ml-4">
          <a
            href=""
            target="_blank"
            rel="noopener"
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="facebook share"
              src={"/icons/share/Share.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
            target="_blank"
            rel="noopener"
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="linkedIn share"
              src={"/icons/share/Linkedin.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a>
          <a
            target="_blank"
            rel="noopener"
            href={`http://www.facebook.com/share.php?u=${window.location.href}`}
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="facebook share"
              src={"/icons/share/Facebook.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a>
          {/* 
            https://stackoverflow.com/questions/62071327/share-content-of-a-webpage-to-instagram-story
          */}
          {/* <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/intent/tweet"
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image  
              alt="twitter share"
              src={"/icons/share/Twitter.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a> */}
        </div>
      </div>
    </div>
  );
};

interface IAssociatedSolution {
  image: string;
  placeholder: string;
  imgAlt: string;
  title: string;
  slug: string;
  learnMore: string;
  associatedSolution: string;
  excerpt: string;
  shareThisArticle?: string;
}

const AssociatedSolution = ({
  image,
  placeholder,
  imgAlt,
  title,
  slug,
  learnMore,
  associatedSolution,
  excerpt,
  shareThisArticle,
}: IAssociatedSolution) => {
  const handleShareArticle = () => {
    const data = {
      url: window.location.href,
    };
    navigator.share(data);
  };

  // to open in popup
  // const handleShareLinkedInIArticle = () => {
  //   window.open(
  //     "http://www.linkedin.com/shareArticle?mini=true&url=" + pathname,
  //     // "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(pathname),
  //     "",
  //     "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
  //   );
  // };
  return (
    <>
      <div className="bg-mellow-10 sm:px-6 sm:flex-row mt-14 flex flex-col gap-10 px-3 py-8">
        <div className="relative flex-1">
          <Image
            src={image}
            className="object-cover w-full h-full"
            alt={imgAlt}
            fill
            sizes="(max-width: 640px) 100vw,(max-width: 1024px) 50vw, 380px"
            placeholder="blur"
            blurDataURL={placeholder || NO_PREVIEW_PLACEHOLDER}
          />
        </div>

        <div className="border-l-[1px] border-royal-blue px-8 flex-1">
          {associatedSolution && (
            <AppText className="text-royal-blue mb-2" type="LABEL_LARGE">
              {associatedSolution}
            </AppText>
          )}
          <AppText className="mb-4" type="TITLE_LARGE">
            {title}
          </AppText>
          <AppText type="BODY_SMALL" className="mb-8">
            {excerpt}
          </AppText>

          <AppButton label={learnMore} link={slug} bg="dark" />
        </div>
      </div>
      <div className="flex mt-10">
        <AppText type="BUTTON_SMALL">{shareThisArticle || "Share this article"}</AppText>
        <div className="flex gap-3 ml-4">
          <button
            onClick={handleShareArticle}
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="share button"
              src={"/icons/share/Share.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </button>

          <a
            target="_blank"
            rel="noopener"
            // https://www.linkedin.com/shareArticle?mini=true&url=url=${window.location.href}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="linkedIn share"
              src={"/icons/share/Linkedin.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a>
          <a
            target="_blank"
            rel="noopener"
            href={`http://www.facebook.com/share.php?u=${window.location.href}`}
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image
              alt="facebook share"
              src={"/icons/share/Facebook.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a>
          {/* 
            https://stackoverflow.com/questions/62071327/share-content-of-a-webpage-to-instagram-story
          */}
          {/* <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/intent/tweet"
            className="bg-black-10 focus:outline-royal-blue flex items-center justify-center w-6 h-6"
          >
            <Image  
              alt="twitter share"
              src={"/icons/share/Twitter.svg"}
              height={0}
              width={0}
              sizes="100vw"
              className="w-4 h-4"
            />
          </a> */}
        </div>
      </div>
    </>
  );
};
